import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import {impresum} from '../components/strings'
import AOS from 'aos';

 class ImpresumPage extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render() {
    const prefix_string = "en";
    return (
      <Layout>
      <Header prefix_string_page={prefix_string}/>
          <div data-aos='fade-zoom-in'>
          
            <section id="p_consulting" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h2 className="title">{impresum}</h2>

                <div className="content">
                <h6>WELL BUSINESS PROMOTION d.o.o.</h6>
                <h6>ADDRESS:</h6>
                <p className="padding-top-small">Irvine Office: <br />
                  Well BP; 3333 Michelson Dr Suite 300, Irvine, CA 92612</p>
                  <p className="padding-top-small">Sarajevo Office: <br />
                  Well Business Promotion; Safeta Mujića 2, Sarajevo 71000 </p>

                <h6>WEBSITE:</h6><p className="padding-top-small"> wellbp.com</p>
                <h6>E-MAIL:</h6> <p className="padding-top-small">info@wellbp.com</p>
                <h6>TELEPHONE:</h6><p className="padding-top-small"> +387 33 831 428</p>

                <h6>COMPANY REGISTRATION NUMBER:</h6>
                <p className="padding-top-small"> ID 4202401300003<br/>PDV 202401300003</p>

                <h6>REGISTER COURT:</h6>
                <p className="padding-top-small">  Općinski sud u Sarajevu, MBS: 65-01-0584-17</p>

                </div>
                </div>
             

                 
        </section>
        </div>
        

       

          <Footer prefix_string_page={prefix_string}/>
      </Layout>
    )
  }
}


export default ImpresumPage